import { ENVIRONMENT } from 'utils/constants';
import experiments from './optimize.json';

const isProd = ENVIRONMENT === 'production',
    defaultExperiment = isProd
        ? 'ZXj2F9LVSw2WzICp1JpEPw'
        : 'epHcdg81Se-Q0uzD116rGA';

export const getCurrentExperimentID = () => defaultExperiment;

export const getVariant = (experiment) =>
    experiment.variants.find((v) => v.weight >= Math.random() * 100);

export const sendExperiment = (experiments = []) => {
    if (experiments.length > 0 && typeof window !== 'undefined') {
        // Send the datalayer with with data from experiments array
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'optimize-experiment',
            exp: experiments.join('!'),
        });
    }
};

export default function getExperiment(id = defaultExperiment) {
    return experiments.find((exp) => {
        if (isProd) {
            return exp.id === id;
        }
        return exp.stagingID === id;
    });
}
