import { createContext } from 'react';

export const VariantContext = createContext();

/**
 * Context Provider for passing data to child components
 *
 * @param  {map}        variant         variant
 * @param  {string}     country         country
 * @param  {function}   children        Function to render children
 * @return {function}
 */
const VariantContextProvider = ({ variant, country, user, children }) => (
    <VariantContext.Provider value={{ variant, country, user }}>
        {children}
    </VariantContext.Provider>
);
export default VariantContextProvider;
