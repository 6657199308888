import { NEXT_PUBLIC_API_DOMAIN } from 'utils/constants';
import * as Sentry from '@sentry/nextjs';

export const getUserData = async () => {
    try {
        const results = await fetch(
            `${NEXT_PUBLIC_API_DOMAIN}/external/user/profile`,
            {
                method: 'GET',
                credentials: 'include',
            },
        )
            .then((response) => {
                if (response.status !== 200) {
                    Sentry.captureMessage(`User Profile Error: ${response}`);
                    return { error: true };
                }
                return response.json();
            })
            .then((res) => {
                if (res.error) {
                    Sentry.captureMessage(`User Profile Error: ${res}`);
                    return { error: true };
                }
                return res;
            });
        return results;
    } catch (error) {
        Sentry.captureException(error);
        return { error: true };
    }
};

export default getUserData;
