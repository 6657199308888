/* eslint-disable one-var */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import '../styles/globals.css';
import React, { useEffect, useState } from 'react';
import { Analytics } from '@vercel/analytics/react';
import Head from 'next/head';
// import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { getCookies, setCookie, getCookie } from 'cookies-next';
import 'normalize.css';
import 'lib/styles/_base.scss';
import { ViewportProvider } from 'utils/context/ViewportContextProvider';
import VariantContextProvider from 'utils/context/VariantContextProvider';
import { sendExperiment } from 'utils/optimize/';
import Script from 'next/script';
import {
    GOOGLE_TAG_MANAGER_CONTAINER,
    // OPTIMIZE_COOKIE_NAME,
    OPTIMIZE_MW_COOKIE_NAME,
    OPTIMIZE_MW_COOKIE_NAME_2, // Added to run multiple MiddleWare Experiments
    COUNTRY_COOKIE_NAME,
    COUNTRY_IS_EU_OR_GB_COOKIE_NAME,
    COOKIE_POLICY,
    COOKIE_DOMAIN,
    // ENVIRONMENT,
} from 'utils/constants';
import reportAccessibility from 'utils/reportAccessibility.ts';
import { cBoolean, getDeviceType } from '@viome/vnxt-nucleus';
import getUserData from 'utils/user/getUserData';
import getUserSubscriptions from 'utils/user/getUserSubscriptions';
import getUserCountry from 'utils/user/getUserCountry';
import { fontsClassNames } from 'lib/fonts/mapping';

function App(props) {
    const {
            Component,
            pageProps,
            clientCountry,
            clientCountryIsEUorGB,
            clientIp,
            clientDevice,
            viomeVariant,
            viomeMWVariant,
            // inExperiment,
        } = props,
        // experiment = getExperiment(),
        // Need to attempt to grab cookies on the client side as well.
        // Because the cookie is created on the client side, the server won't know
        // it exist until a full refresh.
        clientCookies = getCookies(),
        acceptedCookiePolicy = clientCookies[COOKIE_POLICY.NAME] === 'true',
        // experimentCookie = clientCookies[OPTIMIZE_COOKIE_NAME],
        middlewareExperimentCookie = clientCookies[OPTIMIZE_MW_COOKIE_NAME],
        middlewareExperimentCookieTwo =
            clientCookies[OPTIMIZE_MW_COOKIE_NAME_2],
        clientCountryCookie = clientCookies[COUNTRY_COOKIE_NAME],
        clientCountryIsEUorGBCookie =
            clientCookies[COUNTRY_IS_EU_OR_GB_COOKIE_NAME],
        [user, setUserData] = useState(false),
        [userCountry, setUserCountry] = useState(
            // Check both server and client side cookie if country data exist
            clientCountry || clientCountryCookie,
        ),
        userCountryIsEUorGBProps =
            cBoolean(clientCountryIsEUorGB) ||
            cBoolean(clientCountryIsEUorGBCookie),
        [userCountryIsEUorGB, setUserCountryIsEUorGB] = useState(
            userCountryIsEUorGBProps,
        ),
        clientVariant = viomeVariant;

    // Variant/Experiment cookies doesn't exist for the user. We need to bucket
    // the user and set a cookie for a consistent experience
    /*
    if (!viomeVariant && experiment) {
        if (!experimentCookie) {
            const experimentID =
                    ENVIRONMENT === 'production'
                        ? experiment?.id
                        : experiment?.stagingID,
                n = Math.random() * 100,
                variant = experiment.variants.find((v) => v.weight >= n);
            variant.experiment = experimentID;

            clientVariant = {
                experiment: experimentID,
                id: variant.id,
            };

            setCookie(OPTIMIZE_COOKIE_NAME, `${experimentID}.${variant.id}`, {
                domain: COOKIE_DOMAIN,
                maxAge: 30 * 86400, // Thirty Days
                path: '/',
            });
        } else {
            // Cookie didn't exist when the call was made by the server but exist now
            const [experimentID, variantSegment] = experimentCookie.split('.');

            clientVariant = {
                experiment: experimentID,
                id: variantSegment,
            };
        }
    }
    */

    useEffect(() => {
        const experiments = [],
            // VNXT 1014 - https://viomeinc.atlassian.net/browse/VNXT-1008
            // Call Viome api's and store the data in VariantContextProvider
            handleUserData = async () => {
                const userData = await getUserData();
                if (userData.error) return null;

                const userSubscriptions = await getUserSubscriptions(
                        userData?.email,
                    ),
                    hasSubscription =
                        userSubscriptions?.has_active_subscriptions ||
                        userSubscriptions?.status === 'ALL_CANCELLED';
                setUserData({ ...userData, hasSubscription });
                if (userData) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        user: userData,
                        event: 'logged_user',
                    });
                }
                return true;
            },
            // VNXT 1029 - https://viomeinc.atlassian.net/browse/VNXT-1029
            // Call Abstract api's and set a cookie if country data isn't defined
            handleUserCountry = async () => {
                let country = userCountry,
                    countryIsEUorGB = userCountryIsEUorGB;

                if (
                    !country ||
                    country === '-' ||
                    !clientCountryIsEUorGBCookie
                ) {
                    const { countryPhoneCode } = user,
                        countryResponse = await getUserCountry(clientIp);
                    if (countryPhoneCode) {
                        country = countryPhoneCode;
                    }
                    if (!countryResponse?.error) {
                        country = countryResponse?.country_code;
                        countryIsEUorGB =
                            countryResponse?.country_is_eu ||
                            countryResponse?.country_code === 'GB';
                    }
                    if (country) {
                        setUserCountry(country);
                    }
                    setUserCountryIsEUorGB(countryIsEUorGB);
                }

                // Sets a cookie if cookie doesn't already exist and country is vaild
                if (!clientCountryCookie && country) {
                    setCookie(COUNTRY_COOKIE_NAME, `${country}`, {
                        domain: COOKIE_DOMAIN,
                        maxAge: 365 * 86400, // One Year
                        path: '/',
                    });
                }

                // Sets a cookie for country is EU if cookie doesn't already exist
                if (!clientCountryIsEUorGBCookie) {
                    setCookie(
                        COUNTRY_IS_EU_OR_GB_COOKIE_NAME,
                        cBoolean(clientCountryIsEUorGB) ||
                            cBoolean(clientCountryIsEUorGBCookie) ||
                            cBoolean(countryIsEUorGB),
                        {
                            domain: COOKIE_DOMAIN,
                            maxAge: 365 * 86400, // One Year
                            path: '/',
                        },
                    );
                }

                return country;
            };

        // Only send GTM / GA information if the user is apart of the test
        // In this case we define inExperiment (Non Affiliates and not /dave)
        // below and they have to be in the US
        /*
        if (inExperiment && clientCountry === 'US') {
            experiments.push(
                `${clientVariant?.experiment}.${clientVariant?.id}`,
            );
        }
        */

        // If the page a user goes to after landing is an experiment page
        // we want to update the datalayer with the experiment data

        if (viomeMWVariant.length > 0) {
            viomeMWVariant.forEach((mwVariant) => {
                experiments.push(mwVariant);
            });
        } else if (
            middlewareExperimentCookie ||
            middlewareExperimentCookieTwo
        ) {
            // Cookie didn't exist when the call was made by the server but exist now
            // Or User is in experiement but is not currently on an experiment page
            [middlewareExperimentCookie, middlewareExperimentCookieTwo].forEach(
                (mwExperiment) => {
                    if (mwExperiment) {
                        experiments.push(mwExperiment);
                    }
                },
            );
        }

        sendExperiment(experiments);

        handleUserData();
        handleUserCountry();

        const twclid = getCookie('_twclid') || '';
        if (twclid) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                twclid,
                event: 'twclid',
            });
        }
    }, []);

    return (
        <ThemeProvider>
            <ViewportProvider clientDevice={clientDevice}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
                    />
                </Head>
                <VariantContextProvider
                    variant={clientVariant}
                    country={userCountry}
                    user={user}
                >
                    <div className={fontsClassNames}>
                        <Component {...pageProps} />
                    </div>
                </VariantContextProvider>
                {/* Google Tag Manager */}
                <Script
                    id="google-tag-manager"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}

                        gtag('consent', 'default', {
                            // Always grant for non EU/UK
                            'ad_storage': '${
                                acceptedCookiePolicy || !userCountryIsEUorGB
                                    ? 'granted'
                                    : 'denied'
                            }',
                            'analytics_storage': 'granted',
                            'functionality_storage': 'granted',
                            'personalization_storage': 'granted',
                            'security_storage': 'granted',
                        });

                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER}');
                    `,
                    }}
                />
                {/* End Google Tag Manager */}
                <Analytics />
            </ViewportProvider>
        </ThemeProvider>
    );
}

App.getInitialProps = async ({ ctx }) => {
    // Get the client IP address
    const { req, res } = ctx,
        cookies = getCookies({ req, res }),
        // experimentCookie = cookies[OPTIMIZE_COOKIE_NAME],
        // middlewareExperimentCookie = cookies[OPTIMIZE_MW_COOKIE_NAME],
        // middlewareExperimentCookieTwo = cookies[OPTIMIZE_MW_COOKIE_NAME_2],
        countryCookie = cookies[COUNTRY_COOKIE_NAME],
        countryIsEUorGBCookie = cookies[COUNTRY_IS_EU_OR_GB_COOKIE_NAME],
        //  { slug } = query,
        // [pagePath] = ctx.asPath.split('?'),
        extraProps = {};

    if (req && req.headers['x-forwarded-for']) {
        const forwarded = req.headers['x-forwarded-for'],
            ip = forwarded
                ? forwarded.split(',')[0]
                : req.connection?.remoteAddress,
            clientIp = ip !== '::1' ? ip : '1.1.1.1';

        extraProps.clientIp = clientIp;
        extraProps.clientDevice = getDeviceType(req.headers['user-agent']);
    }

    extraProps.clientCountry = countryCookie;
    extraProps.clientCountryIsEUorGB = countryIsEUorGBCookie || false;
    extraProps.viomeMWVariant = [];

    // AB Experiment Exceptions
    /*
    extraProps.inExperiment = true;
    if (
        pagePath.includes('/dave') ||
        pageParams.includes('utm_medium=impact') ||
        pageParams.includes('utm_source=affiliates')
    ) {
        extraProps.inExperiment = false;
    }

    // If not apart of exceptions above, get experiment variables
    if (extraProps.inExperiment) {
        // Check for experiment cookie on the server side
        if (experimentCookie) {
            const [experimentID, variantSegment] = experimentCookie.split('.');
            extraProps.viomeVariant = {
                experiment: experimentID,
                id: variantSegment,
            };
        }
    }
    */

    // https://viomeinc.atlassian.net/browse/JNY-523
    // Homepage A/B Test
    // if (pagePath === '/' || pagePath === '/homepage-variant') {
    //     if (middlewareExperimentCookie) {
    //         extraProps.viomeMWVariant.push(middlewareExperimentCookie);
    //     }
    // }
    // // https://viomeinc.atlassian.net/browse/JNY-300
    // // OTO A/B/C Test
    // if (middlewareExperimentCookieTwo) {
    //     extraProps.viomeMWVariant.push(middlewareExperimentCookieTwo);
    // }

    return extraProps;
};

reportAccessibility(React);

export default App;
